<template>
  <AppModal 
    size="full"
    @closed="isGenerateRecommendationModalOpen = false" 
    :open="isGenerateRecommendationModalOpen"
  >
    <!-- Sticky Top Bar -->
    <div class="z-20 fixed top-0 left-0 w-full bg-white border-b border-gray-200">
      <div class="flex items-center justify-between px-4 py-2">
        <AppButton @click="isGenerateRecommendationModalOpen = false" variant="tertiary" size="base">
          <ArrowLeftIcon class="h-5 w-5 shrink-0" />
        </AppButton>

        <AppButton v-if="!organizationSubscriptionStore.limitExceeded" @click="generateRecommendation()" class="whitespace-nowrap">
          Generate recommendation
        </AppButton>
      </div>
    </div>

    <div v-if="organizationSubscriptionStore.limitExceeded" class="max-w-4xl mx-auto pt-16 pb-28">
      <div class="max-w-2xl mt-6 mb-10">
        <div class="mx-auto flex h-12 w-12 mb-4 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
          </svg>
        </div>

        <h3 class="text-lg font-medium leading-7 text-gray-900 tracking-tight sm:text-2xl">Subscription limit reached</h3>
        <p class="text-gray-500 mb-6">You've reached the usage limit for your current plan.</p>

        <p class="text-lg mb-6">
          Your <span class="font-bold">{{ organizationSubscriptionStore.subscription.plan.title }} plan</span> allows for <span class="font-bold">{{ organizationSubscriptionStore.subscription.plan.limits.recommendations }} recommendations</span> per month. You've used all available recommendations for this billing cycle.
        </p>

        <div class="flex gap-2">
          <AppButton @click="isGenerateRecommendationModalOpen = false" variant="tertiary" size="md">Close</AppButton>
          <AppButton :to="{name: 'settingsBilling', params: {organization: route.params.slug}}" size="md">Upgrade Plan</AppButton>
        </div>
      </div>
    </div>

    <div v-else class="max-w-4xl mx-auto pt-16 pb-28">
      <div class="max-w-2xl mt-6 mb-10">
        <h3 class="text-lg font-medium leading-7 text-gray-900 tracking-tight mb-3 sm:text-2xl">
          Generate a recommendation for step {{ stepIndex + 1 }}
        </h3>

        <p class="text-gray-600">
          MetriFi AI will use the instructions below to generate a new webpage for you.
        </p>
      </div>

      <p class="font-semibold mb-3">Instructions</p>

      <!-- Accordion 1 - UI analysis -->
      <div class="mb-4 border border-gray-300 rounded-lg overflow-clip">
        <div class="flex items-center justify-between h-14 px-4 bg-white cursor-pointer" @click="toggleAccordion('accordion1')">
          <div class="flex gap-2">
            <MinusIcon v-if="accordionStates.accordion1" class="h-6 w-6 text-gray-600"/>
            <PlusIcon v-else class="h-6 w-6 text-gray-600"/>
            <h2 class="font-medium">Compare to higher-converting pages</h2>
          </div>

          <div class="flex items-center gap-2">
            <span class="text-sm text-green-600">Included by default</span>
            <CheckCircleIcon class="h-7 w-7 text-green-600"/>
          </div>
        </div>
        <div v-if="accordionStates.accordion1" class="p-4 bg-gray-50 border-t transition-all duration-300 ease-in-out">
          <p class="text-gray-600">MetriFi AI compares your webpage with higher-converting pages to find opportunities to increase your conversion rate.</p>
        </div>
      </div>

      <!-- Accordion 2 - Competitors -->
      <!-- <div class="mb-4 border border-gray-300 rounded-lg overflow-clip">
        <div class="flex items-center justify-between h-14 px-4 bg-white cursor-pointer" @click="toggleAccordion('accordion2')">
          <div class="flex gap-2">
            <MinusIcon v-if="accordionStates.accordion2" class="h-6 w-6 text-gray-600"/>
            <PlusIcon v-else class="h-6 w-6 text-gray-600"/>
            <h2 class="font-medium">Competitors</h2>
          </div>

          <CheckCircleIcon v-if="hasCompetitors" class="h-7 w-7 text-green-600"/>
        </div>
        <div v-if="accordionStates.accordion2" class="p-4 bg-gray-50 border-t transition-all duration-300 ease-in-out">
          <div class="space-y-4">
            <AppInput v-model="competitors[0]" label="Competitor URL" placeholder="https://example.com"/>
            <AppInput v-model="competitors[1]" label="Competitor URL" placeholder="https://example.com"/>
            <AppInput v-model="competitors[2]" label="Competitor URL" placeholder="https://example.com"/>
          </div>
        </div>
      </div> -->

      <!-- Accordion 3 - Additional information -->
      <div class="mb-4 border border-gray-300 rounded-lg overflow-clip">
        <div class="flex items-center justify-between h-14 px-4 bg-white cursor-pointer" @click="toggleAccordion('accordion3')">
          <div class="flex gap-2">
            <MinusIcon v-if="accordionStates.accordion3" class="h-6 w-6 text-gray-600"/>
            <PlusIcon v-else class="h-6 w-6 text-gray-600"/>
            <h2 class="font-medium">Additional information</h2>
          </div>

          <CheckCircleIcon v-if="recommendationStore.recommendation.prompt && recommendationStore.recommendation.prompt !== '<p></p>' || recommendationStore.recommendation.files.length" class="h-7 w-7 text-green-600"/>
        </div>
        <div v-if="accordionStates.accordion3" class="p-4 bg-gray-50 border-t transition-all duration-300 ease-in-out">
          <div class="space-y-4">
            <p class="text-gray-600">Add more details for MetriFi AI to consider while generating the recommendation.</p>

            <!-- Instructions -->
            <p class="font-semibold mb-1">Instructions</p>
            <AppRichtext v-model="recommendationStore.recommendation.prompt" :editable="true" class="bg-white"/>

            <!-- Upload files -->
            <p class="font-semibold mb-1">Files</p>
            <FileUploader @fileUploaded="handleLocalFileUploaded" class="mb-5"/>

            <!-- Files -->
            <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4">
              <li v-for="file in recommendationStore.recommendation.files" :key="file.id" class="relative">
                <div @click="" class="group relative block cursor-pointer overflow-hidden rounded-lg bg-gray-100 border mb-2">
                  <!-- Thumbnail -->
                  <img :src="file.url" :alt="file.alt" width="400" class="select-none pointer-events-none shrink-0 w-full h-36 object-cover group-hover:opacity-75"/>

                  <!-- Select -->
                  <!-- <button class="absolute flex top-1 left-1 h-7 w-7 items-center justify-center text-gray-600 hover:text-gray-900">
                    <input :checked="selected.includes(file.id)" type="checkbox" class="h-4 w-4 cursor-pointer rounded border-gray-300 text-violet-600 focus:ring-violet-600">
                  </button> -->

                  <!-- Delete -->
                  <button @click.stop="handleDeleteLocalFile(file.id)" class="absolute hidden top-1 right-1 h-7 w-7 group-hover:flex items-center justify-center bg-white rounded-lg text-gray-600 hover:text-gray-900">
                    <TrashIcon class="h-4 w-4"/>
                  </button>
                </div>
                <p class="block truncate text-sm font-medium text-gray-900 mb-1">{{ file.title }}</p>
                <p class="block truncate text-sm text-gray-500">{{ file.filename }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Accordion 4 - Secret shopper information -->
      <div class="mb-4 border border-gray-300 rounded-lg overflow-clip">
        <div class="flex items-center justify-between h-14 px-4 bg-white cursor-pointer" @click="toggleAccordion('accordion4')">
          <div class="flex gap-2">
            <MinusIcon v-if="accordionStates.accordion4" class="h-6 w-6 text-gray-600"/>
            <PlusIcon v-else class="h-6 w-6 text-gray-600"/>
            <h2 class="font-medium">Secret shopping study</h2>
          </div>

          <div class="flex gap-3">
            <a @click.stop href="https://metrifi.com/secret-shop-your-website/" target="_blank" class="px-2.5 py-1.5 text-sm text-violet-600 bg-violet-50 hover:bg-violet-100 font-medium rounded-full active:translate-y-px disabled:pointer-events-none disabled:opacity-50 disabled:shadow-nonefocus-visible:outline-violet-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">Secret shop my website</a>
            <CheckCircleIcon v-if="recommendationStore.recommendation.secret_shopper_prompt && recommendationStore.recommendation.secret_shopper_prompt !== '<p></p>' || recommendationStore.recommendation.secret_shopper_files.length" class="h-7 w-7 text-green-600"/>
          </div>
        </div>
        <div v-if="accordionStates.accordion4" class="p-4 bg-gray-50 border-t transition-all duration-300 ease-in-out">
          <div class="space-y-4">
            <p class="text-gray-600 mb-2">Add insights from a secret shopping study for MetriFi AI to consider while generating the recommendation.</p>

            <!-- Instructions -->
            <p class="font-semibold mb-1">Details</p>
            <AppRichtext v-model="recommendationStore.recommendation.secret_shopper_prompt" :editable="true" class="bg-white"/>

            <!-- Upload files -->
            <p class="font-semibold mb-1">Files</p>
            <FileUploader @fileUploaded="handleSecretShopperFileUploaded" class="mb-5"/>

            <!-- Files -->
            <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4">
              <li v-for="file in recommendationStore.recommendation.secret_shopper_files" :key="file.id" class="relative">
                <div @click="" class="group relative block cursor-pointer overflow-hidden rounded-lg bg-gray-100 border mb-2">
                  <img :src="file.url" :alt="file.alt" width="400" class="select-none pointer-events-none shrink-0 w-full h-36 object-cover group-hover:opacity-75"/>
                  <button @click.stop="handleDeleteSecretShopperFile(file.id)" class="absolute hidden top-1 right-1 h-7 w-7 group-hover:flex items-center justify-center bg-white rounded-lg text-gray-600 hover:text-gray-900">
                    <TrashIcon class="h-4 w-4"/>
                  </button>
                </div>
                <p class="block truncate text-sm font-medium text-gray-900 mb-1">{{ file.title }}</p>
                <p class="block truncate text-sm text-gray-500">{{ file.filename }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </AppModal>
</template>

<script setup>
import { ref, reactive, inject, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import { ArrowLeftIcon, TrashIcon, PlusIcon, MinusIcon, CheckCircleIcon } from '@heroicons/vue/24/solid'
import { useOrganizationSubscriptionStore } from '@/domain/organizations/store/useOrganizationSubscriptionStore'
import { useRecommendationStore } from '@/domain/recommendations/store/useRecommendationStore'
import { useFunnelStore } from '@/domain/funnels/store/useFunnelStore'
import { useFileStore } from '@/domain/files/store/useFileStore'
import AppRichtext from '@/app/components/base/forms/AppRichtext.vue'
import AppButton from '@/app/components/base/buttons/AppButton.vue'
import FileUploader from '@/domain/files/components/FileUploader.vue'

const props = defineProps({
  stepIndex: '',
  prompt: '',
  secretShopperPrompt: '',
})

const route = useRoute()
const router = useRouter()
const organizationSubscriptionStore = useOrganizationSubscriptionStore()
const recommendationStore = useRecommendationStore()
const funnelStore = useFunnelStore()
const fileStore = useFileStore()

const accordionStates = reactive({
  accordion1: false,
  accordion2: false,
  accordion3: false,
  accordion4: false,
});

const isGenerateRecommendationModalOpen = inject('isGenerateRecommendationModalOpen')

// Watch the prop value and update the ref whenever it changes
watch(() => isGenerateRecommendationModalOpen.value, (modelOpen) => {
  if (modelOpen === true) {
    if (route.params.recommendation) {
      // Get the current recommendation
      recommendationStore.show(route.params.organization, route.params.dashboard, route.params.recommendation)
      
    } else {
      // Start a new recommendation in the store
      recommendationStore.recommendation = {
        step_index: props.stepIndex,
        prompt: '',
        files: [],
        secret_shopper_prompt: '',
        secret_shopper_files: [],
        metadata: {},
      }
    }
  } else {
    if (route.params.recommendation) {
      recommendationStore.show(route.params.organization, route.params.dashboard, route.params.recommendation)
    }
  }
});

function handleLocalFileUploaded(file) {
  recommendationStore.recommendation.files.push(file)
}

function handleSecretShopperFileUploaded(file) {
  recommendationStore.recommendation.secret_shopper_files.push(file)
}

function handleDeleteLocalFile(fileId) {
  // We're not working with an existing recommendation, destroy the file
  if (!route.params.recommendation) {
    fileStore.destroy(route.params.organization, fileId)
  }

  recommendationStore.recommendation.files = recommendationStore.recommendation.files.filter(file => file.id !== fileId)
}

function handleDeleteSecretShopperFile(fileId) {
  // We're not working with an existing recommendation, destroy the file
  if (!route.params.recommendation) {
    fileStore.destroy(route.params.organization, fileId)
  }

  recommendationStore.recommendation.secret_shopper_files = recommendationStore.recommendation.secret_shopper_files.filter(file => file.id !== fileId)
}

async function generateRecommendation() {
  // We're creating a new recommendation, generate the metadata
  if (!route.params.recommendation) { 
    recommendationStore.recommendation.metadata = getMetadataForRecommendations(props.stepIndex)
  }

  // Cache file ids
  let fileIds = recommendationStore.recommendation?.files?.map(file => file.id)
  let secretShopperFileIds = recommendationStore.recommendation?.secret_shopper_files?.map(file => file.id)
  console.log('Cached fileIds', fileIds)
  console.log('Cached secretShopperFileIds', secretShopperFileIds)

  // Store recommendation
  await recommendationStore.store(route.params.organization, route.params.dashboard, recommendationStore.recommendation).then(() => {

    // Attach files
    if (fileIds.length) {
      console.log('Attaching files', fileIds)
      recommendationStore.attachFile(route.params.organization, recommendationStore.recommendation.id, fileIds, 'additional-information')
    }

    if (secretShopperFileIds.length) {
      console.log('Attaching secret shopper files', secretShopperFileIds)
      recommendationStore.attachFile(route.params.organization, recommendationStore.recommendation.id, secretShopperFileIds, 'secret-shopper')
    }

    setTimeout(() => {
      console.log('Redirecting to recommendation')
      isGenerateRecommendationModalOpen.value = false

      router.push({ name: 'recommendation', params: { organization: route.params.organization, dashboard: route.params.dashboard, recommendation: recommendationStore.recommendation.id } })
        .then(() => {
            window.location.reload()
        })
    }, 2000)
  })
}

function getMetadataForRecommendations(stepIndex) {
  let index = Number(stepIndex)

  let focusName = funnelStore.funnels[0].report.steps[index].name
  let focusDomain = funnelStore.funnels[0].organization.domain
  let focusUrl = focusDomain + funnelStore.funnels[0].report.steps[index].metrics[0].pagePath
  let conversion = funnelStore.funnels[0].report.steps[index + 1].conversionRate

  let focus = {
    name: focusName,
    domain: focusDomain,
    url: focusUrl,
    conversion: conversion,
  }

  let comparisons = funnelStore.funnels
    .filter((funnel, i) => i !== 0)
    .map((funnel) => {
      let name = funnel.report.steps[index].name
      let domain = funnel.organization.domain
      let url = domain + funnel.report.steps[index].metrics[0].pagePath
      let conversion = funnel.report.steps[index + 1].conversionRate
      
      return {
        name: name,
        domain: domain,
        url: url,
        conversion: conversion,
      };
    });

  // Sort the comparisons by the step conversion rate
  comparisons.sort((a, b) => b.conversion - a.conversion)

  // Get the top three comparisons
  comparisons = comparisons.slice(0, 3)

  return {
    focus: focus,
    comparisons: comparisons,
  }
}

const toggleAccordion = (accordionName) => {
  if (accordionStates.hasOwnProperty(accordionName)) {
    accordionStates[accordionName] = !accordionStates[accordionName];
  }
};
</script>
